<template>
    <v-card class="PaymentDeliveryCard">
        <div class="PaymentDeliveryCard-imageContainer">
            <img
                class="PaymentDeliveryCard-image"
                :src="require(`@/StoreWeb/assets/img/${env}/${icon}.svg`)"
                :alt="icon"
            >
        </div>
        <span class="PaymentDeliveryCard-title">{{ $t(title) }}</span>
        <span
            class="PaymentDeliveryCard-description"
            v-html="$sanitize($t(description))"
        />
        <v-btn
            color="primary"
            size="large"
            @click="mainAction()"
        >
            {{ getLabel }}
        </v-btn>
        <v-btn
            :to="{ name: 'home' }"
            class="no-active"
            color="primary"
            size="large"
            variant="text"
        >
            {{ $t('button:back_to_home') }}
        </v-btn>
        <div class="PaymentDeliveryCard-details">
            <p class="h3 text-left">
                {{ $t('order:details') }}
            </p>
            <cart-basket
                v-for="basket in baskets"
                :key="basket.line"
                :additional-data="getCardReaderDeliverableFareMedias(basket.basket.providerId)"
                :basket="basket.basket"
                :showActions="true"
                @action-triggered="onActionTriggered"
            />
        </div>
        <span
            v-if="needCardReader"
            v-html="linkToCardReader"
        />
    </v-card>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import CartBasket from '@/StoreWeb/components/cart/CartBasket'
import { isEmpty } from 'global-utils'
import * as applicationSessionActionTypes from '@/StoreWeb/store/modules/application-session/action-types'
import * as applicationSessionMutationTypes from '@/StoreWeb/store/modules/application-session/mutation-types'
import * as externalActionTypes from '@/StoreWeb/store/modules/application-session/external-action-types'
import * as internalActionTypes from '@/StoreWeb/store/modules/application-session/internal-action-types'

const emit = defineEmits(['openSupportReaderModal'])

const props = defineProps({
    baskets: {
        type: Array,
        required: true
    },
    buttonLabel: {
        type: String,
        default: ''
    },
    description: {
        type: String,
        required: true
    },
    icon: {
        type: String,
        required: true
    },
    id: {
        type: Number,
        required: true
    },
    needCardReader: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        required: true
    }
})

const { t } = useI18n()
const { isAuthenticated } = useLogin()
const router = useRouter()
const store = useStore()

const env = ref(process.env.VUE_APP_NETWORK_ID)

const applicationSession = computed(() => store.state.applicationSessionModule.applicationSession)
const getLabel = computed(() => props.buttonLabel ? t(props.buttonLabel) : isAuthenticated ? t('payment:success:order_link') : t('wallet-light:title'))

const linkToCardReader = computed(() => {
    return t('payment:success:card_reader')
        .replace('%linkOpeningTag%', `<a href="${t('payment:success:card_reader_link')}" class="PaymentDeliveryCard-link">`)
        .replace('%linkClosingTag%', '</a>')
})

function mainAction () {
    if (props.needCardReader) {
        emit('openSupportReaderModal')
    } else if (isAuthenticated) {
        router.push({ name: 'orderDetails', params: { id: props.id } })
    } else {
        router.push({ name: 'walletLight', params: { providerId: props.baskets[0].providerId, fareMediaId: props.baskets[0].fareMediaId } })
    }
}

function getCardReaderDeliverableFareMedias (providerId) {
    const cardReaderDeliverableFareMedias = []

    props.baskets.reduce((acc, { basket }) => {
        if (
            basket.providerId === providerId &&
                ['CARDREADER_MANDATORY', 'DISTRIBUTION_CARDREADER'].includes(basket.deliveryMode)
        ) {
            const fareMedia = {
                fareMediaId: basket.fareMediaId,
                providerId
            }
            if (basket?.recipientUser?.firstName || basket?.recipientUser?.lastName) {
                fareMedia.recipientUser = basket.recipientUser
            }
            acc.push(fareMedia)
        }
        return acc
    }, cardReaderDeliverableFareMedias)

    return { cardReaderDeliverableFareMedias }
}

async function onActionTriggered (data) {
    if (data.action.name === 'useCardMobileReaderSDK') {
        applicationSession.value.data.waitingForReloadingFareMedias = data.action.deliverableFareMedias
        const baseUrl = `${window.location.origin}/internalLinks/${internalActionTypes.GO_TO_CATALOG}?sessionId=${applicationSession.value.id}&initialRequest=${externalActionTypes.RELOAD_FARE_MEDIA_THROUGH_NFC}`
        applicationSession.value.sessionContext = {
            currentStep: baseUrl,
            resumeStepSuccess: `${baseUrl}&status=success`,
            resumeStepError: `${baseUrl}&status=error`
        }
        store.commit(applicationSessionMutationTypes.SET_APPLICATION_SESSION, applicationSession.value)
        await store.dispatch(applicationSessionActionTypes.UPDATE_APPLICATION_SESSION)
        const externalLink = router.resolve({
            name: 'externalLinks',
            params: { action: externalActionTypes.RELOAD_FARE_MEDIA_THROUGH_NFC },
            query: { sessionId: applicationSession.value.id }
        })
        if (!isEmpty(externalLink)) {
            window.location.href = window.location.origin + externalLink.href
        }
    }
}
</script>

<style lang='scss' scoped>
@import 'globalScss';

.PaymentDeliveryCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $s6;
    padding: $s8 $s4;
    box-shadow: none;
    background: transparent;
    text-align: center;

    @media (min-width: $desktop-breakpoint) {
        padding: $s8;
        border-radius: $borderRadius-xlarge;
        box-shadow: $boxShadow-card;
        background: $backgroundColor-greyGradient;
    }

    &-imageContainer {
        width: 250px;
        max-height: 180px;
        margin-bottom: $s4;
        padding: $s4;
        border-radius: 60px;
        box-shadow: rgb(187, 187, 187, .24) 10px 10px 4px 0;
        background-color: rgba(249, 250, 255, 1);
    }

    &-image {
        width: 100%;
        height: 100%;
    }

    &-title {
        font-size: $font-large;
        font-weight: 600;
        line-height: $font-xlarge;
    }

    &-description {
        font-size: $font-small;
        font-weight: 600;
        line-height: $font-medium;
    }

    &-details {
        width: 100%;
        padding: $s4;
        border-radius: $borderRadius-xlarge;
        background-color: $color-white;
    }

    :deep(.PaymentDeliveryCard-link) {
        font-weight: bold;
        color: $primary_color;
        transition: color .3s ease-in-out;

        &:hover {
            color: $color-brandPrimaryDark;
        }
    }
}
</style>
