<template>
    <div class="ProductAddToCartFlow">
        <product-add-to-cart-flow-debugger v-if="false" />
        <v-alert
            v-if="alertMessage"
            class="ProductAddToCartFlow-alertMessage"
            color="error"
            density="compact"
            prominent
            text
            variant="tonal"
        >
            <div v-html="$sanitize(alertMessage)" />
        </v-alert>
        <div
            v-if="isAddingProductToCart"
            class="d-flex flex-column justify-center align-center gap-4 py-5"
        >
            <v-progress-circular
                indeterminate
                color="primary"
                size="25"
                width="2"
                class="ProductAddToCartFlow-loader"
            />
            <p>{{ $t('add_to_cart_flow:add_to_cart_processing') }}</p>
        </div>
        <div v-show="!isAddingProductToCart">
            <authentication-mode-selector
                v-if="showAuthenticationModeSelector"
                :product="getProduct"
                :title="addToCartFlowStep.label"
                :authenticationModes="getProduct.getUserAuthModes()"
                class="ProductAddToCartFlow-authenticationModeSelector"
                @selected-authentication-mode-updated="updateAuthenticationMode"
            />
            <variation-selector
                v-if="showVariationSelector"
                :product="getProduct"
                :title="addToCartFlowStep.label"
                :variations="getProduct.variations"
                class="ProductAddToCartFlow-variationSelector"
                @selected-variation-updated="updateVariation"
            />
            <sale-option-selector
                v-if="showSaleOptionSelector"
                :chosenFareMedia="getSaleOptionSelectorProps.chosenFareMedia ?? null"
                :saleOptions="getSaleOptionSelectorProps.saleOptions"
                :title="getSaleOptionSelectorProps.title ?? ''"
                class="ProductAddToCartFlow-saleOptionSelector"
                @update-sale-option="updateSaleOption"
                @fare-medias-available-in-account="fareMediasAvailableInAccount"
                @provider-accounts-available="providerAccountsAvailable"
            />
            <quantity-selector
                v-if="showQuantitySelector"
                :max="getProduct.maxNumber"
                :min="getProduct.minNumber"
                :title="addToCartFlowStep.label"
                class="ProductAddToCartFlow-quantitySelector"
                @update-quantity="updateQuantity"
            />
            <documents-selector
                v-if="showDocumentsSelector"
                :documents="addToCartFlowStep.documents"
                :otherFilesTitle="$t('add_to_cart_flow:documents_selector:other_files_title')"
                :providedDocuments="addToCartFlowStep.providedDocuments"
                :title="addToCartFlowStep.label"
                class="ProductAddToCartFlow-documentsSelector"
                @update-documents="updateDocuments"
            />
            <product-parameter-selector
                v-if="showProductParameterSelector"
                :product="getProduct"
                :product-parameter="addToCartFlowStep.productParameter"
                :title="addToCartFlowStep.label"
                :has-error="alertMessage"
                class="ProductAddToCartFlow-productParameterSelector"
                @update-product-parameter="updateProductParameter"
            />
            <div v-if="showPostalProductAddressModal">
                <block-loader v-if="addressPendingRequests.getAddresses" />
                <address-modal-on-product
                    v-else
                    :deliveryMode="saleOptionWithPostalOnProduct"
                    :addToCartFlowManager="addToCartFlowManager"
                    :addresses="addresses"
                />
            </div>
            <terms-of-service-selector
                v-if="showTermsOfServiceApproval"
                :termsOfServiceUrl="addToCartFlowStep.termsOfServiceUrl"
                :title="addToCartFlowStep.label"
                class="ProductAddToCartFlow-termsApproval"
                @update-terms-of-service-approval="updateTermsOfServiceApproval"
            />
            <v-row
                v-if="showButtonsArea && (getShowBackButton || showAddToCartButton || getShowNextButton)"
                :class="[{ 'ProductAddToCartFlow-navigation--withBackButton': getShowBackButton }]"
                class="ProductAddToCartFlow-navigation mt-5"
                no-gutters
            >
                <v-col
                    cols="4"
                    class="d-flex justify-content-start"
                >
                    <v-btn
                        v-if="getShowBackButton"
                        variant="outlined"
                        class="ProductAddToCartFlow-navigationBackButton"
                        @click="goToPreviousStep"
                    >
                        {{ $t('button:back') }}
                    </v-btn>
                </v-col>
                <v-col
                    cols="4"
                    class="d-flex justify-content-end justify-end"
                >
                    <v-btn
                        v-if="showAddToCartButton"
                        :disabled="isGoToNextStepDisabled"
                        type="submit"
                        class="bg-primary"
                        @click.prevent="addToCart"
                    >
                        {{ $t('button:validate') }}
                    </v-btn>
                    <v-btn
                        v-else-if="getShowNextButton"
                        :disabled="isGoToNextStepDisabled"
                        size="large"
                        type="submit"
                        elevation="0"
                        class="bg-primary"
                        @click.prevent="goToNextStep"
                    >
                        {{ getNextStepLabel }}
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, onUnmounted, onBeforeUpdate, ref } from 'vue'
import AuthenticationModeSelector from '@/StoreWeb/components/common/AuthenticationModeSelector'
import QuantitySelector from '@/StoreWeb/components/common/QuantitySelector'
import DocumentsSelector from '@/StoreWeb/components/common/DocumentsSelector'
import VariationSelector from '@/StoreWeb/components/product/VariationSelector'
import SaleOptionSelector from '@/StoreWeb/components/product/SaleOptionSelector'
import ProductParameterSelector from '@/StoreWeb/components/product/ProductParameterSelector'
import ProductAddToCartFlowDebugger from '@/StoreWeb/components/product/ProductAddToCartFlowDebugger'
import TermsOfServiceSelector from '@/StoreWeb/components/product/TermsOfServiceSelector'
import { isEmpty } from 'global-utils'
import { useStore } from 'vuex'
import * as cartMutationTypes from '@/StoreWeb/store/modules/cart/mutation-types'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import CartManager from '@/StoreWeb/managers/CartManagerSingleton'
import BlockLoader from '@/StoreWeb/components/common/BlockLoader.vue'
import AddressModalOnProduct from '@/StoreWeb/components/address/AddressModalOnProduct.vue'
import cloneDeep from 'lodash.clonedeep'
import * as addressActionTypes from '@/StoreWeb/store/modules/address/action-types'
import { useI18n } from 'vue-i18n'
import config from 'config'
import emitter from 'global-emitter'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'

const emit = defineEmits(['askRequiredFareProfiles', 'loginToAskFareProfiles', 'addToCart'])

const store = useStore()
const { t } = useI18n()
const { isAuthenticated } = useLogin()

const alertMessage = ref('')
const addToCartFlowManager = ref(AddToCartFlowManager.getInstance())
const cartManager = ref(CartManager.getInstance())
const currentAuthenticationMode = ref(null)
const currentTermsOfUseApproval = ref(true)
const currentDocuments = ref(null)
const currentProductParameter = ref(null)
const currentQuantity = ref(null)
const currentSaleOption = ref(null)
const currentVariation = ref(null)
const isLastStep = ref(false)
const showPostalProductAddressModal = ref(false)
const addresses = ref(null)
const fareMediaAvailable = ref(false)
const accountsAvailable = ref(true)
const keepAuthenticationMode = ref(false)
const autoSelect = ref(false)
const isAddingProductToCart = ref(false)

const getIsFareMediaLoading = computed(() => store.getters.getAddToCartFlowContextType === 'FARE_MEDIA_LOADING')
const addToCartFlowStep = computed(() => store.state.cartModule.addToCartFlowStep)
const addToCartFlowContext = computed(() => store.state.cartModule.addToCartFlowContext)
const isAddToCartFlowFirstStep = computed(() => store.state.cartModule.isAddToCartFlowFirstStep)
const isAddToCartFlowLastStep = computed(() => store.state.cartModule.isAddToCartFlowLastStep)
const isGoToNextStepDisabled = computed(() => store.state.cartModule.isGoToNextStepDisabled)
const getAddresses = computed(() => store.getters.getAddresses)
const addressPendingRequests = computed(() => store.state.addressModule.addressPendingRequests)
const showAddToCartButton = computed(() => {
    if (
        config.purchase_flow.type === 'default' &&
        (
            accountsAvailableOnSaleOptionStep.value === false ||
            fareMediasAvailableOnSaleOptionsStep.value === false ||
            saleOptionEmailMandatoryIsLastStepInAnonymousMode.value
        )
    ) {
        return false
    }

    return isAddToCartFlowLastStep.value && (showPostalProductAddressModal.value === true || isEmpty(saleOptionWithPostalOnProduct.value))
})
const accountsAvailableOnSaleOptionStep = computed(() => {
    if (
        !addToCartFlowStep.value?.saleOptions ||
        !(addToCartFlowStep.value?.saleOptions.find(saleOption => saleOption.basketInfo?.id === 'PROVIDERACCOUNT'))
    ) {
        return null
    }
    return accountsAvailable.value
})
const fareMediasAvailableOnSaleOptionsStep = computed(() => {
    if (
        !addToCartFlowStep.value?.saleOptions ||
        !(addToCartFlowStep?.value.saleOptions.find(saleOption => saleOption.basketInfo?.id === 'PROVIDERACCOUNTANDFAREMEDIA' || saleOption.basketInfo?.id === 'PROVIDERFAREMEDIA'))
    ) {
        return null
    }

    return fareMediaAvailable.value
})
const saleOptionEmailMandatoryIsLastStepInAnonymousMode = computed(() => {
    let emailMandatorySaleOption = null

    if (addToCartFlowStep.value?.saleOptions) {
        emailMandatorySaleOption = addToCartFlowStep.value?.saleOptions.find(saleOption => saleOption.userAuthMode?.id === 'EMAILMANDATORY')
    }

    return !isAuthenticated.value && isAddToCartFlowLastStep.value && emailMandatorySaleOption
})
const getChosenFareMedia = computed(() => {
    return (!isEmpty(addToCartFlowContext.value) && !isEmpty(addToCartFlowContext.value.chosenFareMedia)) ? addToCartFlowContext.value.chosenFareMedia : null
})
const showButtonsArea = computed(() => {
    return !showPostalProductAddressModal.value &&
        (
            (!autoSelect.value && showSaleOptionSelector.value) ||
            !showSaleOptionSelector.value
        )
})
const getShowNextButton = computed(() => {
    if (
        config.purchase_flow.type === 'default' &&
        (accountsAvailableOnSaleOptionStep.value === false || fareMediasAvailableOnSaleOptionsStep.value === false)
    ) {
        return false
    }

    return !showAuthenticationModeSelector.value && (!isAddToCartFlowLastStep.value || !isEmpty(saleOptionWithPostalOnProduct.value))
})
const getNextStepLabel = computed(() => {
    if (!isEmpty(saleOptionWithPostalOnProduct.value) || !getIsFareMediaLoading.value) {
        return t('button:next')
    }

    const currentFlow = cloneDeep(addToCartFlowManager.value.addToCartFlowSteps)
    const currentStepIndex = cloneDeep(addToCartFlowManager.value.currentStepIndex)

    if (showVariationSelector.value && isEmpty(currentVariation.value)) {
        return t('button:validate')
    }

    const nextStep = addToCartFlowManager.value.getNextStep({
        level: 0,
        currentFlow,
        currentStepIndex
    })

    if (nextStep.isLastStep && nextStep.nextStep.type === 'saleOption') {
        return t('button:validate')
    }

    return t('button:next')
})
const getShowBackButton = computed(() => {
    if (
        config.purchase_flow.type === 'default' &&
        (
            accountsAvailableOnSaleOptionStep.value === false ||
            fareMediasAvailableOnSaleOptionsStep.value === false ||
            saleOptionEmailMandatoryIsLastStepInAnonymousMode.value
        )
    ) {
        return false
    }

    const currentFlow = cloneDeep(addToCartFlowManager.value.addToCartFlowSteps)
    const currentStepIndex = cloneDeep(addToCartFlowManager.value.currentStepIndex)
    const previousStep = addToCartFlowManager.value.getPreviousStep({
        level: 0,
        currentFlow,
        currentStepIndex
    })

    if (!isEmpty(previousStep.previousStep) && previousStep.previousStep.type === 'authenticationMode' && isAuthenticated) {
        return false
    }

    if (addToCartFlowStep.value && !getIsFareMediaLoading.value) {
        return !isAddToCartFlowFirstStep.value
    }

    if (addToCartFlowStep.value && isAddToCartFlowFirstStep.value) {
        return false
    }

    if (!previousStep.isFirstStep) {
        return true
    }

    return !(isEmpty(previousStep.previousStep) || (!isEmpty(previousStep.previousStep) && previousStep.previousStep.type === 'saleOption'))
})
const getProduct = computed(() => {
    if (!isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.product !== null) {
        return addToCartFlowStep.value.product
    }

    return null
})
const saleOptionWithPostalOnProduct = computed(() => {
    if (addToCartFlowStep.value?.product) {
        const saleOptionWithPostalOnProduct = addToCartFlowStep.value.product.saleOptions
            .find(saleOption => saleOption.deliveryMode?.id === 'POSTALONPRODUCT')
        return saleOptionWithPostalOnProduct ? saleOptionWithPostalOnProduct.deliveryMode : null
    } else {
        return addToCartFlowManager.value.addToCartFlowSteps
            .flatMap(addToCartFlowStep =>
                addToCartFlowStep.steps
                    .flatMap(step =>
                        step.saleOptions?.filter(saleOption => saleOption.deliveryMode?.id === 'POSTALONPRODUCT')
                            .map(saleOption => saleOption.deliveryMode)
                    )
            )
            .filter(deliveryMode => deliveryMode != null)
    }
})
const getSaleOptionSelectorProps = computed(() => {
    return {
        chosenFareMedia: getChosenFareMedia.value,
        saleOptions: addToCartFlowStep.value.saleOptions,
        title: addToCartFlowStep.value.label
    }
})
const showAuthenticationModeSelector = computed(() => !isAuthenticated.value && !isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.type === 'authenticationMode' && showPostalProductAddressModal.value === false)
const showDocumentsSelector = computed(() => !isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.type === 'documents' && showPostalProductAddressModal.value === false)
const showProductParameterSelector = computed(() => !isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.type === 'productParameter' && showPostalProductAddressModal.value === false)
const showVariationSelector = computed(() => !isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.type === 'variation' && showPostalProductAddressModal.value === false)
const showQuantitySelector = computed(() => !isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.type === 'quantity' && showPostalProductAddressModal.value === false)
const showSaleOptionSelector = computed(() => !isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.type === 'saleOption' && showPostalProductAddressModal.value === false)
const showTermsOfServiceApproval = computed(() => !isEmpty(addToCartFlowStep.value) && addToCartFlowStep.value.type === 'termsApproval' && showPostalProductAddressModal.value === false)
const isRequiredFareProfiles = computed(() => addToCartFlowManager.value.product.requiredFareProfiles && addToCartFlowManager.value.product.requiredFareProfiles.length > 0)
const getRequiredFareProfiles = computed(() => addToCartFlowManager.value.product?.requiredFareProfiles ?? null)
const getSelectedFareMedia = computed(() => {
    if (currentSaleOption.value.saleOptionParameters && currentSaleOption.value.saleOptionParameters.fareMediaId) {
        return {
            id: currentSaleOption.value.saleOptionParameters.fareMediaId,
            accountId: currentSaleOption.value.saleOptionParameters.providerUserId
        }
    }

    return null
})
const getAddressesForAddressModalOnProduct = computed(() => {
    if (getAddresses.value.length > 0) {
        return getAddresses.value.length > 1
            ? getAddresses.value.find(address => address.defaultAddress === true)
            : getAddresses.value[0]
    }

    return null
})

async function addToCart () {
    if (isValidStep()) {
        isAddingProductToCart.value = true
        try {
            await cartManager.value.addToCart({
                data: addToCartFlowManager.value.addToCartFlowSteps,
                product: addToCartFlowManager.value.product
            })
        } catch (error) {
            store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, true)
            const errorData = error?.response?.data
            alertMessage.value = errorData?.message

            if (errorData?.externalMessage) {
                alertMessage.value += `<br>${errorData.externalMessage}`
            }
            keepAuthenticationMode.value = true
        } finally {
            emit('addToCart')
            isAddingProductToCart.value = false
        }
    } else {
        // eslint-disable-next-line
        console.log('Current step is not valid...')
    }
}

function checkAuthenticationModeStep () {
    if (currentAuthenticationMode.value !== null) {
        addToCartFlowStep.value.selectedAuthenticationMode = currentAuthenticationMode.value.authenticationMode
        addToCartFlowStep.value.selectedAuthenticationModeParameters = currentAuthenticationMode.value.authenticationModeParameters
        updateAddToCartFlowStep()
        return true
    }

    return false
}

function initAutoSelect (autoSelected) {
    autoSelect.value = autoSelected
}

function checkDocumentsStep () {
    let allDocumentsSettled = true
    if (!isEmpty(currentDocuments.value)) {
        let foundDocument
        addToCartFlowStep.value.documents.forEach(document => {
            foundDocument = currentDocuments.value.find(item => item.id === document.id)
            allDocumentsSettled = allDocumentsSettled && !isEmpty(foundDocument) && foundDocument.base64String !== ''
        })
    } else {
        allDocumentsSettled = false
    }

    if (allDocumentsSettled) {
        addToCartFlowStep.value.providedDocuments = currentDocuments.value
        updateAddToCartFlowStep()
        resetErrorMessage()
        return true
    }

    alertMessage.value = t('add_to_cart_flow:documents_selector:error:missing_documents')
    return false
}

function checkProductParameterStep () {
    if (
        !isEmpty(currentProductParameter.value) &&
        currentProductParameter.value.isProductParameterValid
    ) {
        addToCartFlowStep.value.productParameterValues = currentProductParameter.value.productParameterValues
        updateAddToCartFlowStep()
        resetErrorMessage()

        return true
    }

    alertMessage.value = t('add_to_cart_flow:error:default')
    return false
}

function checkQuantityStep () {
    if (currentQuantity.value !== null) {
        addToCartFlowStep.value.selectedQuantity = currentQuantity.value
    }
    if (!isEmpty(addToCartFlowStep.value.selectedQuantity)) {
        updateAddToCartFlowStep()
        resetErrorMessage()

        return true
    }

    alertMessage.value = t('add_to_cart_flow:quantity_selector:error:invalid_value')
    return false
}

function checkSaleOptionStep () {
    if (
        currentSaleOption.value !== null && currentSaleOption.value.isSaleOptionParametersValid &&
        (currentSaleOption.value.isCompatible === undefined || currentSaleOption.value.isCompatible !== false)
    ) {
        addToCartFlowStep.value.selectedSaleOption = currentSaleOption.value.saleOption
        if (getIsFareMediaLoading.value) {
            const selectedSaleOptionParameters = {
                accountId: '',
                email: '',
                support: {
                    fareMediaId: addToCartFlowContext.value.chosenFareMedia.fareMediaId,
                    providerId: addToCartFlowContext.value.chosenFareMedia.providerId
                }
            }
            if (!isEmpty(addToCartFlowContext.value.chosenFareMedia.providerUserExternalId)) {
                selectedSaleOptionParameters.support.providerUserExternalId = addToCartFlowContext.value.chosenFareMedia.providerUserExternalId
            }
            addToCartFlowStep.value.selectedSaleOptionParameters = selectedSaleOptionParameters
        }
        if (!isEmpty(addToCartFlowStep.value.selectedSaleOption.deviceId) && !isEmpty(addToCartFlowStep.value.selectedSaleOption.deviceLabel)) {
            const selectedSaleOptionParameters = {
                support: {}
            }
            selectedSaleOptionParameters.support.deviceId = addToCartFlowStep.value.selectedSaleOption.deviceId
            selectedSaleOptionParameters.support.deviceLabel = addToCartFlowStep.value.selectedSaleOption.deviceLabel
            addToCartFlowStep.value.selectedSaleOptionParameters = selectedSaleOptionParameters
        } else {
            addToCartFlowStep.value.selectedSaleOptionParameters = currentSaleOption.value.saleOptionParameters
        }

        if (isRequiredFareProfiles.value && isAuthenticated.value) {
            emit('askRequiredFareProfiles', {
                requiredFareProfiles: getRequiredFareProfiles.value,
                selectedFareMedia: getSelectedFareMedia.value
            })

            return false
        }

        if (isRequiredFareProfiles.value && !isAuthenticated.value) {
            emit('loginToAskFareProfiles', {
                requiredFareProfiles: getRequiredFareProfiles.value
            })

            return false
        }

        updateAddToCartFlowStep()
        resetErrorMessage()

        return true
    }
    alertMessage.value = t('add_to_cart_flow:sale_option_selector:error:empty_provider_account_and_fare_media')
    if (
        currentSaleOption.value === null &&
        !isEmpty(addToCartFlowManager.value.currentStep) &&
        !isEmpty(addToCartFlowManager.value.currentStep.saleOptions)
    ) {
        alertMessage.value = t('add_to_cart_flow:sale_option_selector:error:empty_' + getUnselectedSaleOptionErrorSuffixe())
    } else if (currentSaleOption.value?.isCompatible === false) {
        if (!isEmpty(currentSaleOption.value.saleOption)) {
            alertMessage.value = t('add_to_cart_flow:sale_option_selector:error:incompatible_' + getSelectedSaleOptionErrorSuffixe(currentSaleOption.value.saleOption))
        } else {
            alertMessage.value = t('add_to_cart_flow:sale_option_selector:error:incompatible_' + getUnselectedSaleOptionErrorSuffixe())
        }
    }
    return false
}

function checkTermsOfServiceApprovalStep () {
    if (!isEmpty(currentTermsOfUseApproval.value)) {
        addToCartFlowStep.value.isApproved = currentTermsOfUseApproval.value
        updateAddToCartFlowStep()
        if (currentTermsOfUseApproval.value) {
            resetErrorMessage()

            return true
        }
    }

    alertMessage.value = t('add_to_cart_flow:terms_approval_selector:error:invalid_value')
    return false
}

function checkVariationStep () {
    if (!isEmpty(currentVariation.value)) {
        updateAddToCartFlowStep()
        resetErrorMessage()

        return true
    }

    alertMessage.value = t('add_to_cart_flow:variation_selector:error:invalid_value')
    return false
}

function isValidStep () {
    let isValidStep = false
    if (showAuthenticationModeSelector.value) {
        isValidStep = checkAuthenticationModeStep()
    }
    if (showDocumentsSelector.value) {
        isValidStep = checkDocumentsStep()
    }
    if (showVariationSelector.value) {
        isValidStep = checkVariationStep()
    }
    if (showQuantitySelector.value) {
        isValidStep = checkQuantityStep()
    }
    if (showProductParameterSelector.value) {
        isValidStep = checkProductParameterStep()
    }
    if (showSaleOptionSelector.value) {
        isValidStep = checkSaleOptionStep()
    }
    if (showTermsOfServiceApproval.value) {
        isValidStep = checkTermsOfServiceApprovalStep()
    }
    return isValidStep
}

function getSelectedSaleOptionErrorSuffixe (saleOption) {
    switch (saleOption.basketInfo.id) {
        case 'PROVIDERACCOUNTANDFAREMEDIA':
            return 'provider_account_and_fare_media'
        case 'PROVIDERACCOUNTANDDEVICE':
            return 'provider_device'
        case 'PROVIDERACCOUNT':
            return 'provider_account'
        case 'PROVIDERFAREMEDIA':
            return 'provider_fare_media'
    }
    return 'provider_account_and_fare_media'
}

function getUnselectedSaleOptionErrorSuffixe () {
    if (isAuthenticated.value) {
        if (addToCartFlowManager.value.currentStep.saleOptions.some(saleOption => {
            return saleOption.userAuthMode.id === 'USERACCOUNT' &&
                saleOption.basketInfo.id === 'PROVIDERACCOUNTANDFAREMEDIA'
        })) {
            return 'provider_account_and_fare_media'
        } else if (addToCartFlowManager.value.currentStep.saleOptions.some(saleOption => {
            return saleOption.userAuthMode.id === 'USERACCOUNT' &&
                saleOption.basketInfo.id === 'PROVIDERACCOUNTANDDEVICE'
        })) {
            return 'provider_device'
        } else if (addToCartFlowManager.value.currentStep.saleOptions.some(saleOption => {
            return saleOption.userAuthMode.id === 'USERACCOUNT' &&
                saleOption.basketInfo.id === 'PROVIDERACCOUNT'
        })) {
            return 'provider_account'
        } else if (addToCartFlowManager.value.currentStep.saleOptions.some(saleOption => {
            return saleOption.userAuthMode.id === 'USERACCOUNT' &&
                saleOption.basketInfo.id === 'PROVIDERFAREMEDIA'
        })) {
            return 'provider_fare_media'
        }
    } else if (addToCartFlowManager.value.currentStep.saleOptions.some(saleOption => {
        return saleOption.userAuthMode.id === 'EMAILMANDATORY' &&
            saleOption.basketInfo.id === 'PROVIDERFAREMEDIA'
    })) {
        return 'provider_fare_media'
    }
    return 'provider_account_and_fare_media'
}

function goToPreviousStep () {
    store.commit(cartMutationTypes.SET_IS_GO_TO_NEXT_STEP_DISABLED, false)
    resetErrorMessage()
    addToCartFlowManager.value.goToPreviousStep({ keepAuthenticationMode: keepAuthenticationMode.value })
    keepAuthenticationMode.value = false
}

async function goToNextStep () {
    if (isValidStep() && isAddToCartFlowLastStep.value && !isEmpty(saleOptionWithPostalOnProduct.value)) {
        if (isAuthenticated.value) {
            await store.dispatch(addressActionTypes.GET_ADDRESSES)
            await store.dispatch(addressActionTypes.GET_ADDRESS_TEMPLATES)
        }

        addresses.value = getAddressesForAddressModalOnProduct.value
        showPostalProductAddressModal.value = true
        isLastStep.value = true
    } else if (isValidStep()) {
        await addToCartFlowManager.value.goToNextStep()
    } else {
        // eslint-disable-next-line
        console.log('Current step is not valid...')
    }
}

function updateAuthenticationMode (selectedAuthenticationMode) {
    currentAuthenticationMode.value = selectedAuthenticationMode

    if (!isEmpty(selectedAuthenticationMode.goToNext) && selectedAuthenticationMode.goToNext) {
        goToNextStep()
    }
}

function updateDocuments (selectedDocuments) {
    currentDocuments.value = selectedDocuments
    if (alertMessage.value !== '') {
        checkDocumentsStep()
    }
}

function updateProductParameter (selectedProductParameter) {
    currentProductParameter.value = selectedProductParameter
    if (alertMessage.value !== '') {
        checkProductParameterStep()
    }
}

function updateQuantity (selectedQuantity) {
    currentQuantity.value = selectedQuantity
    if (alertMessage.value !== '') {
        checkQuantityStep()
    }
}

async function updateSaleOption (selectedSaleOption) {
    currentSaleOption.value = selectedSaleOption
    if (alertMessage.value !== '') {
        checkSaleOptionStep()
    }
    if ((!isEmpty(selectedSaleOption.goToNext) && selectedSaleOption.goToNext) || selectedSaleOption.goToNext === undefined) {
        if (!isAddToCartFlowLastStep.value || (isAddToCartFlowLastStep.value && !isEmpty(saleOptionWithPostalOnProduct.value))) {
            await goToNextStep()
        } else if (isEmpty(saleOptionWithPostalOnProduct.value)) {
            await addToCart()
        }
    }
}

function updateTermsOfServiceApproval (isApproved) {
    currentTermsOfUseApproval.value = isApproved

    if (alertMessage.value !== '') {
        checkTermsOfServiceApprovalStep()
    }
}

function updateVariation (selectedVariation) {
    currentVariation.value = selectedVariation
    addToCartFlowStep.value.selectedVariation = selectedVariation

    if (alertMessage.value !== '') {
        checkVariationStep()
    }
}

function updateAddToCartFlowStep () {
    const currentStep = addToCartFlowManager.value.getCurrentStep()
    Object.assign(currentStep, addToCartFlowStep.value)
    addToCartFlowManager.value.currentStep = addToCartFlowStep.value
    const isLastStep = addToCartFlowManager.value.currentStepIndex.equals(addToCartFlowManager.value.getLastStepIndex())
    store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_LAST_STEP, isLastStep)
}

function fareMediasAvailableInAccount (data) {
    fareMediaAvailable.value = data
}

function providerAccountsAvailable (data) {
    accountsAvailable.value = data
}

function resetErrorMessage () {
    alertMessage.value = ''
}

onBeforeMount(() => {
    emitter.on('autoSelectChecked', initAutoSelect)
})

onMounted(() => {
    const isFirstStep = addToCartFlowManager.value.isFirstStep()
    store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP, isFirstStep)

    if (addToCartFlowStep.value !== null && addToCartFlowStep.value.type === 'authenticationMode' && isAuthenticated.value) {
        addToCartFlowManager.value.goToNextStep()
    }
})

onBeforeUpdate(() => {
    if (
        !(addToCartFlowStep.value?.type === 'end' && isEmpty(saleOptionWithPostalOnProduct.value)) &&
        addToCartFlowStep.value?.type === 'authenticationMode' && isAuthenticated.value
    ) {
        addToCartFlowManager.value.goToNextStep()
    }

    const isFirstStep = addToCartFlowManager.value.isFirstStep()
    store.commit(cartMutationTypes.SET_IS_ADD_TO_CART_FLOW_FIRST_STEP, isFirstStep)
})

onUnmounted(() => {
    emitter.off('autoSelectChecked', initAutoSelect)
})
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductAddToCartFlow {
    &-alertMessage {
        margin-bottom: 20px;
    }

    &-navigation {
        display: flex;
        justify-content: space-between;
        text-align: center;

        &Button {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &BackButton {
            cursor: pointer;
            font-weight: $fontWeight-defaultBold;
        }
    }
}

@media (min-width: $tablet-breakpoint) {
    .ProductAddToCartFlow {
        &-navigation {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &Button {
                margin: 0;
            }

            &PreviousButton {
                order: 1;
            }

            &NextButton,
            &AddToCartButton {
                order: 2;
            }

            &--withBackButton {
                justify-content: space-between;
            }
        }
    }
}
</style>
