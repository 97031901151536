<template>
    <component
        :is="getComponent"
        :to="linkToProductPage && { name: 'product', params: { id: product.id, slug: normalizeText(product.name) } }"
        :class="[(sectionStyle !== '' ? `ProductItem--${sectionStyle}` : ''), (isNonSaleable ? 'ProductItem--nonSaleable' : '')]"
        class="ProductItem"
    >
        <div class="ProductItem-photoWrapper">
            <img
                :src="getImage"
                :alt="getImageAlt"
                class="ProductItem-photo"
            >
        </div>
        <div class="ProductItem-infos">
            <div class="ProductItem-header">
                <component
                    :is="getTitleTag"
                    class="ProductItem-name mb-0"
                >
                    {{ product.name.ucFirst() }}
                    <v-tooltip
                        v-if="product.isCardReaderMandatory()"
                        location="bottom"
                    >
                        <template #activator="{ props }">
                            <icomoon-icon
                                v-bind="props"
                                :layersCount="3"
                                name="CardReader"
                                class="ProductItem-cardReaderMandatoryIcon"
                            />
                        </template>
                        <span>{{ $t('add_to_cart_flow:card_reader_mandatory') }}</span>
                    </v-tooltip>
                </component>
                <p class="mt-0">
                    {{ product.providerNetworkLabel.ucFirst() }}
                </p>
                <div
                    v-if="hasTargetIcons"
                    class="ProductItem-targets"
                >
                    <icomoon-icon
                        v-for="target in targetIcons"
                        :key="target.id"
                        :name="target.icon"
                        class="ProductItem-targetIcon"
                    />
                </div>
                <v-card
                    v-if="product.shortDescription !== ''"
                    class="mb-3"
                    dense
                    elevation="0"
                    color="lightGray1"
                >
                    <v-card-text
                        class="pa-2"
                    >
                        <p
                            class="ProductItem-shortDescription ma-0"
                            v-html="$sanitize(product.shortDescription)"
                        />
                    </v-card-text>
                </v-card>
                <small v-if="debugInformationsDisplay">{{ product.id }}</small>
            </div>
            <div class="ProductItem-footer">
                <div class="ProductItem-fareAndButtons d-flex flex-wrap justify-space-between align-center gap-2">
                    <price
                        :product="product"
                        :size="sectionStyle === 'highlighted' ? 'Medium' : 'Small'"
                        detail-level="REDUCED"
                        class="ProductItem-price ProductItem-fareWrapper"
                    />
                    <div class="d-flex flex-grow-1 justify-end">
                        <v-btn
                            v-if="showExpressPurchase"
                            :title="$t('button:quick_purchase')"
                            class="ProductItem-button rounded"
                            color="secondary"
                            size="small"
                            variant="flat"
                            icon="mdi-clock-fast"
                            @click.prevent="expressPurchaseAddToCart()"
                        />
                        <v-btn
                            v-else-if="showAddToCart"
                            :title="$t('button:add')"
                            class="ProductItem-button rounded"
                            color="primary"
                            size="small"
                            variant="flat"
                            icon="mdi-close"
                            @click.prevent="addCartAction"
                        >
                            <template #default>
                                <icomoon-icon
                                    :name="'Bag--filled'"
                                    class="ProductItem-buttonIcon"
                                />
                            </template>
                        </v-btn>
                        <router-link
                            v-if="linkToProductPage && showMoreInfo"
                            :to="{ name: 'product', params: { id: product.id, slug: normalizeText(product.name) } }"
                            class="ProductItem-button ProductItem-buttonMoreInfo"
                        >
                            {{ $t('more:info') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <p
            v-if="debugInformationsDisplay"
            :class="['ProductItem-type--' + product.modalType ]"
            class="ProductItem-type"
            @click.stop.prevent="showDebugModal"
        >
            {{ product.modalType }}
        </p>
    </component>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import emitter from 'global-emitter'
import { useStore } from 'vuex'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon'
import { isEmpty } from 'global-utils'
import addCartFlowMixins from '@/StoreWeb/js/mixins/add-cart-flow-utils'
import Price from '@/StoreWeb/components/common/Price'
import { normalizeText } from '@/StoreWeb/js/mixins/autocomplete-utils'
import * as cartActionTypes from '@/StoreWeb/store/modules/cart/action-types'
import { useLogin } from '@/StoreWeb/js/composables/login-utils'
import router from '@/router/router'
import { checkHasToCreateMspAccount, triggerAddAccountEvent } from '@/StoreWeb/js/composables/msp-account-utils'
import CartSerializer from '@/StoreWeb/serializers/CartSerializerSingleton'

const cartSerializer = ref(CartSerializer.getInstance())
const { storeAddCartFlow, triggerLogin } = addCartFlowMixins()
const store = useStore()
const { t } = useI18n()
const { isAuthenticated } = useLogin()

const componentProps = defineProps({
    index: {
        type: Number,
        required: true
    },
    isDisplayed: {
        type: Boolean,
        default: true
    },
    sectionStyle: {
        type: String,
        default: ''
    },
    level: {
        type: Number,
        default: 2
    },
    product: {
        type: Object,
        required: true
    },
    linkToProductPage: {
        type: Boolean,
        default: true
    }
})

const cart = computed(() => store.state.cartModule.cart)
const getUserStatus = computed(() => store.getters.getUserStatus)
const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const userPendingRequests = computed(() => store.state.userModule.userPendingRequests)
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const getHasSelectedFareMedia = computed(() => {
    return selectedFareMedia.value && !isEmpty(selectedFareMedia.value) && selectedFareMedia.value.id !== undefined
})
const targetIcons = computed(() => componentProps.product.getTargets())
const hasTargetIcons = computed(() => targetIcons.value.length > 0)

const getTitleTag = computed(() => {
    if (componentProps.level === 1) {
        return 'h2'
    } else if (componentProps.level === 2) {
        return 'h3'
    } else if (componentProps.level === 3) {
        return 'h4'
    }
    return 'h5'
})
const isNonSaleable = computed(() => {
    return componentProps.product.saleableUnit.id === 'NON_SALEABLE' && componentProps.product.getFare() === 0
})
const getImage = computed(() => {
    if (!isEmpty(componentProps.product.fareMedia)) {
        return componentProps.product.fareMedia.image
    } else {
        return componentProps.product.image
    }
})
const getImageAlt = computed(() => {
    if (componentProps.product?.imageAlt) {
        return componentProps.product.imageAlt
    }
    return ''
})
const showAddToCart = computed(() => {
    return componentProps.product.saleableUnit.id !== 'NON_SALEABLE' &&
        (
            getHasSelectedFareMedia.value !== true ||
            (
                getHasSelectedFareMedia.value === true &&
                componentProps.product.requiredFareProfiles.length === 0
            )
        )
})
const showExpressPurchase = computed(() => {
    return showAddToCart.value && componentProps.product.expressPurchase
})
const showMoreInfo = computed(() => {
    return getHasSelectedFareMedia.value === true && componentProps.product.requiredFareProfiles.length > 0
})
const getComponent = computed(() => {
    return componentProps.linkToProductPage && !showExpressPurchase.value ? 'router-link' : 'div'
})

const addCartAction = async () => {
    if (isAuthenticated.value) {
        const { hasToCreateMspAccount, provider } = checkHasToCreateMspAccount(componentProps.product)

        if (!userPendingRequests.value.getWallet && getUserStatus.value === 'BLOCKED_PAYMENT_REQUIRED') {
            emitter.emit('showAlertModal', {
                messageHTML: t('wallet:blocked_payment_required:message'),
                title: t('wallet:blocked_payment_required:title')
            })

            return
        }

        if (hasToCreateMspAccount) {
            triggerAddAccountEvent(provider)

            return
        }

        emitter.emit('showProductModal', componentProps.product)

        return
    }

    if ((componentProps.product.modalType === 'MODALPRODUCT' || componentProps.product.modalType === 'MODALPRODUCTGROUP') && !isEmpty(componentProps.product.getUserAuthModes())) {
        if (componentProps.product.hasToLoginOnAddToCart()) {
            storeAddCartFlow({
                product: componentProps.product
            })
        }

        emitter.emit('showProductModal', componentProps.product)

        return
    }

    storeAddCartFlow({
        product: componentProps.product
    })

    triggerLogin({
        callback: emitter.emit('hideProductModal', { keep: true })
    })
}
const showDebugModal = (event) => {
    emitter.emit('showDebugModal', {
        objectToDump: componentProps.product,
        title: 'Propriétés du produit #' + componentProps.product.id
    })
}

const expressPurchaseAddToCart = async () => {
    const basketInfo = store.state.userModule.isAuthenticated ? 'PROVIDERACCOUNTANDFAREMEDIA' : 'PROVIDERFAREMEDIA'
    const currentOption = componentProps.product.saleOptions.find(option => option.basketInfo.id === basketInfo)
    const deliveryMode = currentOption ? currentOption.deliveryMode?.id : 'DISTRIBUTION'
    const mainAccountProviderUser = ref(null)

    const isAssociatedAccount = cartSerializer.value.isAssociatedProviderUser(componentProps.product.saleOptions[0].provider.id, selectedFareMedia.value?.providerUserExternalId)

    if (isAssociatedAccount) {
        mainAccountProviderUser.value = cartSerializer.value.getMainAccountProviderUser(componentProps.product.saleOptions[0].provider.id)
    }

    const body = {
        orderId: cart.value.id,
        items: [
            {
                type: componentProps.product.modalType,
                expressPurchase: true,
                id: componentProps.product.id,
                version: 1,
                saleableUnit: componentProps.product.saleableUnit.unit,
                quantity: componentProps.product.quantity,
                saleOption: {
                    target: componentProps.product.saleOptions[0].target.id,
                    deliveryMode,
                    basketInfo,
                    providerId: componentProps.product.saleOptions[0].provider.id,
                    providerUserId: mainAccountProviderUser.value ? mainAccountProviderUser.value.id : selectedFareMedia.value?.providerUserId ?? '',
                    providerUserFirstName: selectedFareMedia.value?.account?.firstName ?? selectedFareMedia.value?.firstName,
                    providerUserLastName: selectedFareMedia.value?.account?.lastName ?? selectedFareMedia.value?.lastName,
                    providerUserExternalId: mainAccountProviderUser.value ? mainAccountProviderUser.value.providerUserExternalId : selectedFareMedia.value?.providerUserExternalId ?? '',
                    fareMediaId: selectedFareMedia.value.id ?? ''
                },
                parameters: componentProps.product.preSetProductParameters
            }
        ]
    }
    const { recipientUser } = selectedFareMedia.value
    if (recipientUser?.associationId || recipientUser?.associationType) {
        body.items[0].recipientUser = recipientUser.getAddToCartBody()
    }
    const productToAdd = componentProps.product
    await store.dispatch(cartActionTypes.ADD_TO_CART, {
        addToCartBody: body
    })
    await router.push({ name: 'cart' })
    productToAdd.parameters = productToAdd.preSetProductParameters
}
</script>

<style lang="scss" scoped>
@import 'globalScss';

.ProductItem {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
    position: relative;
    padding: 10px 12px 12px;
    border-radius: 5px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .11);
    background: #fff;
    color: $color-defaultText;

    &-photo {
        max-width: 72px;
        max-height: 72px;

        &Wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 72px;
        }
    }

    &-infos {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    &-name {
        display: flex;
        align-items: baseline;
        margin: 0 0 $padding-small 0;
        font-family: $fontFamily-title;
        font-size: 16px;
        font-weight: $fontWeight-titleBold;

        @media (min-width: $tablet-breakpoint) {
            font-size: 20px;
        }
    }

    &-cardReaderMandatoryIcon {
        display: block;
        margin-top: 2px;
        margin-left: $s2;
        font-size: $font-medium;
        color: $color-brandSecondary;
    }

    &-targetIcon {
        font-size: 32px;
        color: $color-brandSecondary;
    }

    &-shortDescription {
        font-size: 14px;
    }

    &-price {
        margin: 0;
        padding-right: 10px;
    }

    &-button {
        &Icon {
            font-size: 20px;
        }

        &MoreInfo {
            width: auto;
            height: auto;
            padding: 8px 10px;
            border-radius: $border-radius-xsmall;
            background: $color-brandPrimary;
            font-size: 13px;
            font-weight: 500;
            color: $color-white;
        }
    }

    &--highlighted {
        padding-top: 20px;

        .ProductItem-name {
            margin-bottom: 25px;
            font-size: 18px;

            @media (min-width: $tablet-breakpoint) {
                font-size: 22px;
            }
        }

        .ProductItem-price {
            margin-bottom: $s10;
            padding-right: 0;
        }
    }

    &-type {
        position: absolute;
        top: -8px;
        left: 10px;
        height: 16px;
        margin: 0;
        padding: 0 5px;
        background: forestgreen;
        font-size: 12px;
        line-height: 16px;
        color: #fff;

        &--MODALPACKAGE {
            background: #415971;
        }

        &--MODALPRODUCTGROUP {
            background: #ae2a2e;
        }
    }
}
</style>
